import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Maps from "../components/Map"

const ContactPage = () => {
  const { data } = useStaticQuery(graphql`
    {
      data: markdownRemark(frontmatter: { id: { eq: "contact" } }) {
        ...SEO
        html
        frontmatter {
          title
          image {
            childImageSharp {
              fluid(maxWidth: 1083, maxHeight: 762, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const {
    html,
    frontmatter: { title, ogImage, image },
  } = data

  return (
    <>
      <SEO title={title} ogImage={ogImage} />

      <Layout>
        <div className="c-page-title">
          <div className="u-inner">
            <h1>{title}</h1>
          </div>
          <div className="c-contact">
            <div className="c-contact__main">
              <div className="u-inner">
                <div className="o-grid o-grid--bottom o-grid--flush">
                  <div className="o-grid__cell u-1/1 u-1/2@desktop">
                    <div
                      className="c-contact__content"
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </div>
                  <div className="o-grid__cell u-1/1 u-1/2@desktop">
                    <div className="c-contact__image">
                      <Img
                        style={{ width: "100%", height: "100%" }}
                        fluid={image.childImageSharp.fluid}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Maps />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ContactPage
